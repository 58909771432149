(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	// module functions
	EWO.MD({
		global: EWO.Election,
		name: 'electionStatusPref',
		containerClass: 'js-electionStatusPref',
		attachToElement: '.js-election-container',
		listTemplate: '/app/election/statusPref/templates/statusPref.ejs',
		createOrEditTemplate: '/app/election/statusPref/templates/statusPrefCreateOrEdit.ejs',
		createOrEditValidationRules: {
			rules: {
				votes: {
					required: true,
					number: true
				}
			}
		},
		createOrEditAdditionalData: function(id, filter, qs) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		createOrEditCallback: function() {
			calcSum();
			$('#municipalityId').select2();
		},
		listAdditionalData: function(filter) {
			return $.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies?constituencyId=' + filter.constituencyId + '&electionId=' + filter.electionId);
		},
		listCallback: function() {
			$('#municipalityId').select2();
		},
		datatablesOptions: {
			ordering: false,
			paging: false
		},
		beforeSaveCallback: function(data) {
			data.values = $('.n-votes').map(function(i, e) {
				return {
					id: Number($(e).attr('data-id')),
					votes: Number($(e).val())
				};
			}).toArray();
			return data;
		},
		apiRoot: EWO.config.apiUrlPrefix + '/survey/statusPreference',
		routingRoot: '/election/:electionId/constituency/:constituencyId/prefvotes'
	});

	function calcSum() {
		tot = 0;
		$('.n-votes').each(function() {
			tot += Number(this.value);
		});
		$('#sumPrefVotes').text(tot);
	}

	//events
	$(document).on('change', '.js-electionStatusPref .n-votes', function(ev) {
		calcSum();
	});

	// module startup

	//setup routing
})();
