(function() {
	// globals
	window.EWO.Election = window.EWO.Election || {};


	var constituencyId;

	// module functions
	EWO.Election.renderConstitution = function(ctx) {
		var urlQueryString = new URLSearchParams(ctx.querystring);
		var filter = Object.fromEntries(urlQueryString);
		$.extend(filter, ctx.params);

		$.when(
			ejs.preloadTemplate('/app/election/constitution/templates/constitution.ejs'),
			$.get(EWO.config.apiUrlPrefix + '/constitutions', filter),
			$.get(EWO.config.apiUrlPrefix + '/constituencies/municipalitiesforconstituencies', filter),
		)
		.done(function(templateUrl, list, municipalities) {
			$('.js-election-container').html(ejs.rr(templateUrl, {
				list: list[0],
				data: municipalities,
				filter: filter
			}));
		});

		constituencyId = filter.constituencyId;
	};


	//events
	$(document).on('submit', '.js-electionConstitution .js-filterform', function(ev) {
		var el = $(ev.currentTarget);
		ev.preventDefault();
		var filter = el.serialize();
		page(document.location.pathname + (filter ? '?' + filter : ''));
	});

	$(document).on('click', '.js-electionConstitution .js-publish', function(ev) {

		var el = $(ev.currentTarget).parent();
			ev.preventDefault();

		var sectionId = el.attr('data-sectionId');
		var electionEventId = el.attr('data-electionEventId');

		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/constitutions/publish/' + sectionId + '/' + electionEventId,
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});

	$(document).on('click', '.js-electionConstitution .js-unpublish', function(ev) {

		var el = $(ev.currentTarget).parent();
			ev.preventDefault();

		var sectionId = el.attr('data-sectionId');
		var electionEventId = el.attr('data-electionEventId');

		$.ajax({
			type: 'POST',
			url: EWO.config.apiUrlPrefix + '/constitutions/unpublish/' + sectionId + '/' + electionEventId,
			contentType: 'application/json; charset=UTF-8'
		})
		.done(function() {
			page(document.location.pathname);
		});
	});

	// module startup

	//setup routing
	page('/election/:electionId/constituency/:constituencyId/constitution', window.EWO.Election.renderConstitution);

})();
